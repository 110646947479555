/*--------------------
Body
--------------------*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  min-height: 450px;
  height: 100vh;
  margin: 0;  
  background: radial-gradient(ellipse farthest-corner at center top, #f39264 0%, #f2606f 100%);
  color: #fff;
  font-family: 'Open Sans', sans-serif;  
}

.front {
    text-align: center;
    padding: 25% !important;
    width: 100vw !important;
    height: 100vh !important;
    margin-top: auto !important;
}