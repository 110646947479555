
/* STRUCTURE */
.wrapper {
  padding: 5px;
  max-width: 1200px;
  width: 100%;
  margin: 20px auto;
}

header {
  padding: 0 15px;
}

.columns {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 15px 0;
}

.column {
  flex: 1;
  border: 1px solid white;
  margin: 15px;
  padding: 10px;
}
.column:first-child {
  margin-left: 0;
}
.column:last-child {
  margin-right: 0;
}

@media screen and (max-width: 980px) {
  .columns .column {
    margin-bottom: 30px;
    flex-basis: 40%;
  }
  .columns .column:nth-last-child(2) {
    margin-right: 0;
  }
  .columns .column:last-child {
    flex-basis: 100%;
    margin: 0;
  }
}
@media screen and (max-width: 680px) {
  .columns .column {
    flex-basis: 100%;
    margin: 0 0 30px 0;
  }
}